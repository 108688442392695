import React from "react"
import Layout from "../components/layout.js"
import Meta from "../components/meta.js"
export default function Discord() {
  return (
    <Layout>
      <Meta />
      <meta http-equiv="refresh" content="0;url=https://docs.google.com/forms/d/1SeoFxsSgezeO7waBH4YYhSxRX8CF8AIM75cpr1zjmiE/edit" />
    </Layout>
  )
}